body {
  background-color: rgb(234, 234, 234) !important;
}

.background-orange-gradient {
  background: linear-gradient(180deg, #fff, #fff5e5 45%, #fff);
}

.background-blue-gradient {
  background: linear-gradient(180deg, #fff, #cce3fd 45%, #fff);
}

.header.item {
  font-size: 12pt;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-color: gray;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 3em;
  font-weight: bolder;
  color: #1977f2;
  font-family: sans-serif;
}

.masthead h2 {
  font-size: 1.5em;
  font-weight: lighter;
}

.resetpassword {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.resetpassword .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.resetpassword h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.resetpassword h2 {
  font-size: 1.7em;
  font-weight: normal;
}

h1 {
  font-size: 30pt;
}

.inline.fields {
  margin-bottom: 0 !important;
}

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  padding-top: 5px;
  padding-left: 2%;
  padding-right: 2%;
  color: silver;
}

.InitialFooter {
  position: fixed;
  text-align: center;
  border-bottom: none;
  border-left: none;
  border-right: none;
  bottom: 5px;
  left: 0;
  top: auto;
  right: auto;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.CookiesMessage {
  padding-top: 10px;
  padding-bottom: 85px;
  padding-left: 20px;
  width: 100%;
  height: 35px;
  background-color: green;
  color: white;
  font-size: 12pt;
}

.MobileCookiesMessage {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  background-color: green;
  color: white;
  font-size: 12pt;
  padding-bottom: 50px;
}

.MobileCookiesMessage button {
  margin-top: 10px !important;
}

.InitialCookiesMessage {
  position: fixed;
  text-align: center;
  padding-top: 8px;
  bottom: 30px;
  width: 100%;
  height: 50px;
  right: 0;
  background-color: green;
  color: white;
  font-size: 12pt;
}

.InitialMobileCookiesMessage {
  position: fixed;
  text-align: center;
  bottom: 60px;
  width: 100%;
  right: 0;
  color: dimgray;
  font-size: 12pt;
  padding-left: 10px;
  padding-right: 10px;
}

/*end home page styles*/

.planBadge {
  position: absolute;
  margin-left: -65px;
  margin-top: -75px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: red;
}

.planBadgeText {
  margin-top: 25px;
  font-size: 17pt;
  font-weight: bold;
  display: block;
  white-space: pre-line;
  text-align: center;
  color: white;
}

.textCrossed {
  text-decoration: line-through;
}

.planItem {
  margin-bottom: 15px;
}

.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.site-statistics {
  margin-left: 10% !important;
  margin-right: 10% !important;
  margin-top: 150px !important;
}

.site-statistics-title {
  font-size: 35pt;
  font-weight: bold;
}

.site-statistics-description {
  margin-top: 25px;
  font-size: 14pt;
}

.site-statistics-icon {
  margin-bottom: 20px;
  font-size: 20pt;
}

.testimonials {
  margin: 100px 15% 0 15% !important;
}

.testimonials-items {
  font-size: 13pt !important;
  text-align: center;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  opacity: "70%";
}

.testimonials-centered-item {
  margin-top: -25px !important;
  opacity: "100%";
}

.testimonials-item-author {
  text-align: right;
  margin-top: 12px;
  font-size: 13pt;
}

.testimonials-item-passed-exam {
  font-size: 10pt;
}

.faq {
  margin: 90px 25% 0 25% !important;
}

.faq-title {
  font-size: 13pt !important;
}

.faq-content {
  font-size: 12pt !important;
}

.checkout-plan-content {
  font-size: 12pt !important;
}

/* RichEditor */
.ql-container {
  min-height: 10em;
}